import "core-js/modules/es.array.push.js";
import { defineComponent, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { getQueryInVueRouteQuery, removeQueryInVueRouteQuery } from '@/base/utils/url';
import { scLog } from '@/base/log/sclog';
const DEFAULT_SEARCH_KEY = 'query';
export default defineComponent({
  name: 'TInputSearch',
  props: {
    value: {
      type: String,
      default: ''
    },
    searchKey: {
      type: String,
      default: DEFAULT_SEARCH_KEY
    },
    placeholder: {
      type: String,
      default: ''
    },
    replaceQueryOnSearch: {
      type: Boolean,
      default: false
    },
    emitSearchOnMount: {
      type: Boolean,
      default: false
    }
  },
  emits: ['search', 'input', 'clear'],
  setup(props, ctx) {
    const route = useRoute();
    const router = useRouter();
    const routeQuery = route.query;
    const query = getQueryInVueRouteQuery(routeQuery, props.searchKey);
    let inputValue = ref(props.value || query);
    watch(() => props.value, newVal => {
      inputValue.value = newVal;
    });
    const handleSearch = () => {
      inputValue.value = inputValue.value.trim();
      scLog('click', {
        elem: 'search-btn',
        query: inputValue.value
      });
      if (!inputValue.value) {
        return;
      }
      ctx.emit('search', inputValue.value);
      if (props.replaceQueryOnSearch && inputValue.value) {
        router.push({
          query: {
            ...route.query,
            [props.searchKey]: inputValue.value
          }
        });
      }
    };
    const clearSearch = () => {
      inputValue.value = '';
      if (props.replaceQueryOnSearch) {
        const query = {
          ...removeQueryInVueRouteQuery(route.query, props.searchKey)
          // category: 'newest', @这里不应该参与业务逻辑
        };
        router.push({
          query
        });
      }
      ctx.emit('clear');
    };
    const handleInput = val => {
      ctx.emit('input', val);
    };
    onMounted(() => {
      if (query && props.emitSearchOnMount) {
        handleSearch();
      }
    });
    return {
      inputValue,
      handleSearch,
      clearSearch,
      handleInput
    };
  }
});
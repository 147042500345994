import { defineComponent } from 'vue';
import { Close } from '@element-plus/icons-vue';
export default defineComponent({
  name: 'TResultHint',
  props: {
    searchWord: {
      type: String,
      default: () => ''
    },
    resultCount: {
      type: Number,
      default: 0
    },
    isLoading: {
      type: Boolean,
      default: true
    }
  },
  emmits: ['cancleSearch'],
  setup(props, context) {
    function cancleSearch() {
      context.emit('cancleSearch');
    }
    return {
      cancleSearch,
      Close
    };
  }
});
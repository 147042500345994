
export enum NEWS_CATEGORY_ENUM {
    newest = 'newest',
    market = 'market',
    trends = 'trends',
};

export const NEWS_CATEGORY_MAP = {
    newest: {
        name: 'newest',
        text: '最新资讯',
        value: NEWS_CATEGORY_ENUM.newest,
    },
    market: {
        name: 'market',
        text: '外贸动态',
        value: NEWS_CATEGORY_ENUM.market,
    },
    trends: {
        name: 'trends',
        text: '营销干货',
        value: NEWS_CATEGORY_ENUM.trends,
    },
};

import {IHomeNews, IHomeBanner, IHomePartner} from '../interface/home';
import {API} from '@/base/http';

export const PATHS = {
    banner: '/index/banner',
    partner: '/index/partner',
    news: '/index/news',
};

// get 首页 banner 模块
// http://ku.baidu-int.com/knowledge/HFVrC7hq1Q/pKzJfZczuc/9nUaBjaVOI/15Uh1-7sIDhVC5
export const getHomeBanner = () => {
    return API.get<{list: IHomeBanner[]}>(PATHS.banner);
};

// get 首页合作伙伴模块
// http://ku.baidu-int.com/knowledge/HFVrC7hq1Q/pKzJfZczuc/9nUaBjaVOI/NKoH2W7bZAkKEQ
export const getHomePartner = () => {
    return API.get<{list: IHomePartner[]}>(PATHS.partner);
};

// get 首页资讯模块
// http://ku.baidu-int.com/knowledge/HFVrC7hq1Q/pKzJfZczuc/9nUaBjaVOI/7ko_Jx17Ti16K6
export const getHomeNews = () => {
    return API.get<IHomeNews>(PATHS.news);
};

import { defineComponent } from 'vue';
import { handleUrl } from '@/base/utils/url';
import { scLog } from '@/base/log/sclog';
const config = [{
  key: 'market',
  title: '外贸动态'
}, {
  key: 'trends',
  title: '营销干货'
}, {
  key: 'seolist',
  title: '相关跨境电商文章'
}];
export default defineComponent({
  name: 'TNavNews',
  props: {
    navNewsCards: {
      type: Object,
      default: () => ({})
    }
  },
  setup() {
    const linkClick = (news, index, item) => {
      scLog('click', {
        elem: 'news-item-right-click',
        pos: index.toString(),
        articleId: news.id,
        articleCategory: item.key
      });
    };
    return {
      config,
      handleUrl,
      linkClick
    };
  }
});
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-0ee5a2cb"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "news-content"
};
const _hoisted_2 = {
  class: "side-left"
};
const _hoisted_3 = {
  key: 0
};
const _hoisted_4 = {
  class: "side-right"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_t_input_search = _resolveComponent("t-input-search");
  const _component_intro_banner = _resolveComponent("intro-banner");
  const _component_t_result = _resolveComponent("t-result");
  const _component_el_tab_pane = _resolveComponent("el-tab-pane");
  const _component_el_tabs = _resolveComponent("el-tabs");
  const _component_el_pagination = _resolveComponent("el-pagination");
  const _component_t_nav_news = _resolveComponent("t-nav-news");
  const _component_feedback = _resolveComponent("feedback");
  const _component_t_layout = _resolveComponent("t-layout");
  return _openBlock(), _createBlock(_component_t_layout, {
    class: "news"
  }, {
    default: _withCtx(() => {
      var _ctx$newsData$_ctx$st, _ctx$newsData$_ctx$st2;
      return [_createVNode(_component_intro_banner, {
        title: "出海资讯",
        desc: "汇聚海内外最新资讯动态、做您出海起航的决策前哨"
      }, {
        default: _withCtx(() => [_createVNode(_component_t_input_search, {
          value: _ctx.state.query,
          onSearch: _ctx.handleSearch,
          onClear: _ctx.clearSearch
        }, null, 8, ["value", "onSearch", "onClear"])]),
        _: 1
      }), _createElementVNode("section", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_withDirectives(_createElementVNode("div", null, [_createVNode(_component_t_result, {
        "is-loading": _ctx.newsListloading,
        list: _ctx.newsData.search.items,
        total: _ctx.newsData.search.total
      }, null, 8, ["is-loading", "list", "total"])], 512), [[_vShow, _ctx.state.pageType === 'newsSearch']]), _withDirectives(_createElementVNode("div", null, [_createVNode(_component_el_tabs, {
        modelValue: _ctx.state.activeDataName,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.state.activeDataName = $event)
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.NEWS_CATEGORY_MAP, category => {
          return _openBlock(), _createBlock(_component_el_tab_pane, {
            key: category.name,
            label: category.text,
            name: category.name
          }, {
            default: _withCtx(() => [_createVNode(_component_t_result, {
              "is-loading": _ctx.newsListloading,
              list: _ctx.newsData[category.name].items
            }, null, 8, ["is-loading", "list"])]),
            _: 2
          }, 1032, ["label", "name"]);
        }), 128))]),
        _: 1
      }, 8, ["modelValue"])], 512), [[_vShow, _ctx.state.pageType === 'newsHome']]), (_ctx$newsData$_ctx$st = _ctx.newsData[_ctx.state.activeDataName]) !== null && _ctx$newsData$_ctx$st !== void 0 && _ctx$newsData$_ctx$st.total ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_el_pagination, {
        layout: "prev, pager, next, jumper",
        total: (_ctx$newsData$_ctx$st2 = _ctx.newsData[_ctx.state.activeDataName]) === null || _ctx$newsData$_ctx$st2 === void 0 ? void 0 : _ctx$newsData$_ctx$st2.total,
        background: "",
        onCurrentChange: _ctx.handleChangePageaction,
        "onUpdate:pageSize": _ctx.handleChangePageaction
      }, null, 8, ["total", "onCurrentChange", "onUpdate:pageSize"])])) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_t_nav_news, {
        "nav-news-cards": _ctx.navCardList
      }, null, 8, ["nav-news-cards"])])]), _createVNode(_component_feedback)];
    }),
    _: 1
  });
}
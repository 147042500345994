import { defineComponent, ref } from 'vue';
import { loadRightBar } from '@/base/utils/ufo';
import { scLog } from '@/base/log/sclog';
import ClientOnly from '@/components/ClientOnly/index.vue';
import JoinusForm from '@/views/components/Joinus/JoinusForm.vue';
export default defineComponent({
  name: 'TFeedback',
  components: {
    JoinusForm,
    ClientOnly
  },
  setup() {
    const dialogVisible = ref(false);
    const openForm = () => {
      scLog('click', {
        elem: 'fix-inquiry-form-btn'
      });
      dialogVisible.value = true;
    };
    const openFeed = () => {
      scLog('click', {
        elem: 'fix-feedback-btn'
      });
      loadRightBar();
    };
    const submitSuccess = () => {
      dialogVisible.value = false;
    };
    return {
      loadRightBar,
      openForm,
      openFeed,
      dialogVisible,
      submitSuccess
    };
  }
});
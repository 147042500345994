import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-0c8d8cee"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "news-card"
};
const _hoisted_2 = {
  class: "nav-news-name"
};
const _hoisted_3 = {
  class: "news-title-list"
};
const _hoisted_4 = ["href", "onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("section", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.config, item => {
    var _ctx$navNewsCards$ite, _ctx$navNewsCards$ite2;
    return _openBlock(), _createElementBlock("div", {
      key: item.key,
      class: _normalizeClass(['nav-news-card', {
        'no-card': !((_ctx$navNewsCards$ite = _ctx.navNewsCards[item.key]) !== null && _ctx$navNewsCards$ite !== void 0 && _ctx$navNewsCards$ite.length)
      }])
    }, [(_ctx$navNewsCards$ite2 = _ctx.navNewsCards[item.key]) !== null && _ctx$navNewsCards$ite2 !== void 0 && _ctx$navNewsCards$ite2.length ? (_openBlock(), _createElementBlock(_Fragment, {
      key: 0
    }, [_createElementVNode("h4", _hoisted_2, _toDisplayString(item.title), 1), _createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navNewsCards[item.key], (news, index) => {
      return _openBlock(), _createElementBlock("a", {
        key: news.title,
        class: "news-title-list-item",
        href: _ctx.handleUrl(`news/detail/${news.id}`),
        target: "_blank",
        onClick: $event => _ctx.linkClick(news, index, item)
      }, _toDisplayString(news.title), 9, _hoisted_4);
    }), 128))])], 64)) : _createCommentVNode("", true)], 2);
  }), 128))]);
}
import "core-js/modules/es.array.push.js";
import { defineComponent, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import TNewsSkeleton from './NewsSkeleton.vue';
import TResultHint from './ResultHint.vue';
import { removeQueryInVueRouteQuery, handleUrl } from '@/base/utils/url';
import TEmpty from '@/views/News/components/TEmpty/index.vue';
import { scLog } from '@/base/log/sclog';
import { IS_NODE } from '@/base/utils';
export default defineComponent({
  name: 'TNewsResult',
  props: {
    list: {
      type: Array,
      default: () => []
    },
    total: {
      type: Number,
      default: 0
    },
    isLoading: {
      type: Boolean,
      default: true
    }
  },
  components: {
    TEmpty,
    TResultHint,
    TNewsSkeleton
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const searchWord = computed(() => route.query.query);
    const resultLength = computed(() => props.list.length);
    const formatTimestamp = time => {
      if (time) {
        return time.split(' ')[0].replace(/\./g, '-');
      }
      return '';
    };
    const newsClick = (item, index) => {
      scLog('click', {
        elem: 'news-item-click',
        pos: index.toString(),
        articleId: item.id
      });
    };
    const cancleSearch = () => {
      const query = {
        ...removeQueryInVueRouteQuery(route.query, 'query'),
        category: 'newest'
      };
      router.push({
        query
      });
    };
    const handleTag = item => {
      const tagUrl = router.resolve({
        name: 'LabelSearchList',
        params: {
          keyword: item
        }
      });
      if (!IS_NODE) {
        window.open(tagUrl.href, '_blank');
      }
    };
    return {
      handleUrl,
      cancleSearch,
      formatTimestamp,
      searchWord,
      resultLength,
      newsClick,
      handleTag
    };
  }
});
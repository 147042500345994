import "core-js/modules/es.array.push.js";
/**
 * 页面大致逻辑为:
 * 1. 初始化打开页面时 -> 现获取 url 里的 query 信息, 根据是否有 'query' 参数，且 'query' 参数的值不为空
 * 判断是进入搜索 还是 进入 资讯首页, 标识字段为 pageType: 'newsSearch' | 'newsHome';
 * 根据 pageType 进行组装资讯列表请求的入参; 搜索的入参为 'query', 资讯首页获取列表数据的入参为 type: 'newest' | 'market' | 'trends'
 * 数据请求成功后 把数据放入对应的 newsData[type]里 -> 页面展示;newData 结构为 newsData: {search: {items, page, total}, newest, market, trends}
 *
 * 2. 页面初始化成功后的页面更新 -> 是通过监听路由的 query 来实现更新
 * 切换分类 tab 时更改路由 query 里的 'type' 参数的值 -> 从而触发路由的监听 来实现数据请求更新
 * 搜索时 更改路由的 query 里的 'query' 参数的值 -> 从而触发路由的监听 来实现数据请求更新
 *
 * 3. 分页切换调用 handleChangePageaction 发起请求更新
 *
 * TODO
 * 1. 请求异常处理 包含数据类别切换时请求失败、分页的请求失败、搜索请求失败
 * 2. 优化 - 当前有数据时 不重新请求
 */
import { useHead } from '@vueuse/head';
import { computed, defineComponent, reactive, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ElPagination } from 'element-plus';
import TResult from './components/result.vue';
import { NEWS_ENUM } from '@/base/api/interface/home';
import { mergeHomeApi } from '@/base/api/merge';
import { getNewsList } from '@/base/api/news';
import { NEWS_CATEGORY_MAP } from '@/base/constants/news';
import useStoreRequest from '@/base/hooks/useStoreRequest';
import { getQueryInVueRouteQuery, removeQueryInVueRouteQuery } from '@/base/utils/url';
import Feedback from '@/components/Feedback/index.vue';
import IntroBanner from '@/components/IntroBanner/index.vue';
import TLayout from '@/components/Layout/Layout.vue';
import TInputSearch from '@/components/TInputSearch/index.vue';
import { NewsMutationEnum } from '@/store/news';
import TNavNews from '@/views/News/components/NavNews.vue';
import { NEWS_SEO_CONFIG } from '@/base/config/seo';
import { apmLog } from '@/base/hooks/useApm/apmLog';
const PAGE_SIZE = 10;
const INIT_NEWS_DATA = {
  search: {
    items: [],
    page: 1,
    total: 0
  },
  newest: {
    items: [],
    page: 1,
    total: 0
  },
  market: {
    items: [],
    page: 1,
    total: 0
  },
  trends: {
    items: [],
    page: 1,
    total: 0
  }
};
/* eslint-disable-next-line */
export default defineComponent({
  name: 'News',
  components: {
    TLayout,
    TInputSearch,
    IntroBanner,
    Feedback,
    TResult,
    TNavNews,
    ElPagination
  },
  setup() {
    apmLog();
    useHead(NEWS_SEO_CONFIG);
    const state = reactive({
      pageType: 'newsHome',
      query: '',
      activeDataName: 'newest'
    });
    // 根据路由判断是展示 资讯首页 还是 搜索页
    const router = useRouter();
    const route = useRoute();
    const initPageInfo = routeQuery => {
      // 先根据路由的query 里是否有 query 参数判断是不是搜索
      // 如果不是 搜索 则在路由的 query 里获取 category 参数
      routeQuery = routeQuery || route.query;
      // // 获取搜索的关键词: 有 - 搜索页; 无 - 资讯首页
      state.query = decodeURIComponent(getQueryInVueRouteQuery(routeQuery, 'query')).trim();
      state.pageType = state.query ? 'newsSearch' : 'newsHome';
      state.activeDataName = state.pageType === 'newsSearch' ? 'search' : getActiveCategory(routeQuery);
    };
    initPageInfo();
    // 发送获取资讯列表信息请求
    const {
      resData,
      getData
    } = useCreateRequest(state);
    const newsListloading = computed(() => resData.value.isLoading);
    // 获取右侧新闻资讯卡片的内容
    const {
      state: navNewsList
    } = useStoreRequest({
      space: 'news',
      mutationName: NewsMutationEnum.SET_MERGE_HOME_DATA,
      stateKey: 'mergeHomeData'
    }, mergeHomeApi, {
      routers: ['news']
    });
    const news = computed(() => {
      var _navNewsList$value$da;
      return (_navNewsList$value$da = navNewsList.value.data) === null || _navNewsList$value$da === void 0 ? void 0 : _navNewsList$value$da.news;
    });
    const trendsList = computed(() => {
      var _news$value;
      return ((_news$value = news.value) === null || _news$value === void 0 ? void 0 : _news$value[NEWS_ENUM.trends].slice(0, 5)) || [];
    });
    const marketList = computed(() => {
      var _news$value2;
      return ((_news$value2 = news.value) === null || _news$value2 === void 0 ? void 0 : _news$value2[NEWS_ENUM.market].slice(0, 5)) || [];
    });
    const navCardList = computed(() => ({
      trends: trendsList.value,
      market: marketList.value
    }));
    const newsData = computed(() => {
      if (resData.value.hasError) {
        // TODO 请求异常处理 包含数据类别切换时请求失败、分页的请求失败、搜索请求失败
        return INIT_NEWS_DATA;
      }
      // 防止在新数据请求回来之前 就把老的数据填充到了新的数据结构里
      if (!resData.value.isLoading) {
        var _resData$value$data, _resData$value$data2, _resData$value$data3;
        INIT_NEWS_DATA[state.activeDataName].items = ((_resData$value$data = resData.value.data) === null || _resData$value$data === void 0 ? void 0 : _resData$value$data.items) || [];
        INIT_NEWS_DATA[state.activeDataName].total = (_resData$value$data2 = resData.value.data) === null || _resData$value$data2 === void 0 ? void 0 : _resData$value$data2.total;
        INIT_NEWS_DATA[state.activeDataName].page = (_resData$value$data3 = resData.value.data) === null || _resData$value$data3 === void 0 ? void 0 : _resData$value$data3.page;
      }
      return INIT_NEWS_DATA;
    });
    // 因为在 router 上绑定了 key 所以不需要显式的监听路由的变化，暂注释
    watch(() => route.query, newVal => {
      // 在页面切换到其他页面时 这里的路由监听也会执行 暂时的解决方案是判断是不是当前页面的路由
      if (route.path !== '/news') {
        return;
      }
      if (newVal.query) {
        return;
      }
      initPageInfo(newVal);
      const params = createParams(state);
      getData(params);
    });
    // 监听分类的变化 更改路由
    watch(() => state.activeDataName, newVal => {
      if (newVal === 'search') {
        return;
      }
      // 删除路由里的 query 属性
      const query = {
        ...removeQueryInVueRouteQuery(route.query, 'query'),
        category: newVal
      };
      router.push({
        query
      });
    });
    const clearSearch = () => {
      // 删除路由里的 query 属性
      const query = {
        ...removeQueryInVueRouteQuery(route.query, 'query')
      };
      router.push({
        query
      });
    };
    const handleSearch = async value => {
      if (value) {
        // 删除路由里的 category 属性
        const query = {
          ...removeQueryInVueRouteQuery(route.query, 'category'),
          query: value
        };
        router.push({
          query
        });
        initPageInfo(query);
        const params = createParams(state);
        getData(params);
      }
    };
    const handleChangePageaction = currentPage => {
      let params = createParams(state);
      if (currentPage % 1 !== 0) {
        currentPage = Math.ceil(currentPage);
      }
      params.page = currentPage;
      getData(params);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    };
    return {
      NEWS_CATEGORY_MAP,
      state,
      newsData,
      newsListloading,
      navCardList,
      pageSize: PAGE_SIZE,
      clearSearch,
      handleSearch,
      handleChangePageaction
    };
  }
});
/**
 * 创建页面初始化时的数据请求
 */
function useCreateRequest(state) {
  // 根据在路由上获取的参数判断是资讯首页还是搜索页 从而使用不同的参数发送获取资讯列表的请求
  const params = createParams(state);
  const {
    state: resData,
    getData
  } = useStoreRequest({
    space: 'news',
    mutationName: NewsMutationEnum.SET_NEWS_SEARCH_DATA,
    stateKey: 'newsSearchData'
  }, getNewsList, params);
  return {
    resData,
    getData
  };
}
function createParams(state) {
  let params = {};
  switch (state.pageType) {
    // 资讯页
    case 'newsHome':
      params = {
        term: state.activeDataName
      };
      break;
    // 搜索页
    case 'newsSearch':
      params = {
        query: state.query
      };
      break;
  }
  return params;
}
/**
 * 通过超链接获取当前的 category
 * @param {routeQuery} 路由 query 对象
 * @return {"newest" | "trends" | "market"}
 */
function getActiveCategory(routeQuery) {
  let category = getQueryInVueRouteQuery(routeQuery, 'category');
  const categories = Object.keys(NEWS_CATEGORY_MAP);
  const activeCategory = categories.includes(category) ? category : categories[0];
  return activeCategory;
}
import {PATHS as HomePath} from '../home';
import {IHomeReq, IHomeRes} from '../interface/home';
import {API} from '@/base/http';

const PATHS = {
    merge: '/merge',
    ...HomePath,
};

export const LOG_MERGE_HOME_API = '/openapi/portal/service/merge';

// const PREFIX = process.env.VUE_APP_BASE_API || '';

/**
 * 宣导页 / merge 接口
 * merge 接口可通过 [path1, path2] 任意组合接口、拿到结果，返回值为 {path1: data1, path2: data2} 的结构
 * http://ku.baidu-int.com/knowledge/HFVrC7hq1Q/pKzJfZczuc/9nUaBjaVOI/Wp3_kU26RIlsUY
 */

/**
 * 首页 merge 接口
 * todo 根据推导入参及对应结果、
 */
export const mergeHomeApi = (params: IHomeReq) => {
    const url = `${PATHS.merge}?routes=${params.routers.map(i => `${i}`)}`;
    return API.get<IHomeRes>(url);
};

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-957095a8"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "search-result-hint"
};
const _hoisted_2 = {
  class: "search-keyword"
};
const _hoisted_3 = {
  class: "search-result-count"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createTextVNode(" 含"), _createElementVNode("span", _hoisted_2, " “" + _toDisplayString(_ctx.searchWord) + "”", 1), _createTextVNode(" 最新资讯结果为 "), _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.isLoading ? '...' : _ctx.resultCount), 1), _createTextVNode(" 条 "), _createVNode(_component_el_button, {
    type: "primary",
    class: "close-btn",
    icon: _ctx.Close,
    onClick: _ctx.cancleSearch
  }, {
    default: _withCtx(() => [_createTextVNode(" 撤销 ")]),
    _: 1
  }, 8, ["icon", "onClick"])]);
}
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'TIntroBanner',
  props: {
    propsClass: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    desc: {
      type: String,
      default: ''
    }
  }
});
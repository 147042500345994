import useLogServer, {IUseLogServerConfig} from '@/base/hooks/useApm/useLogServer';
import useLogRender from '@/base/hooks/useApm/useLogRender';
import {IS_NODE} from '@/base/utils';

export const apmLog = (LogServerExpReqUrlConfig?: IUseLogServerConfig) => {
    if (!IS_NODE) {
        if (LogServerExpReqUrlConfig) {
            useLogServer(LogServerExpReqUrlConfig);
        }
        useLogRender();
    }
};
import {nextTick} from 'vue';
import {getApmInstance, getPageUrl, isApmReady} from './utils';

/**
 * 用于放在每个独立的页面中记录当前页面的静态资源加载时长(sst)
 * @description https://ku.baidu-int.com/knowledge/HFVrC7hq1Q/pKzJfZczuc/HDeySYhuyv/O-RKEhHIxVKcH6#anchor-571466e1-b252-11ec-b34c-6bfd89749842
 */
export default function useLogRender() {
    if (!isApmReady()) {
        return;
    }

    // 组件渲染开始
    getApmInstance().logRender(0, getPageUrl());

    // 组件渲染结束
    nextTick(() => {
        getApmInstance().logRender(1, getPageUrl());
    });
}

import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-50e4a63c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "intro-banner__title"
};
const _hoisted_2 = {
  key: 1,
  class: "intro-banner__desc"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['intro-banner', _ctx.propsClass])
  }, [_ctx.title ? (_openBlock(), _createElementBlock("h1", _hoisted_1, _toDisplayString(_ctx.title), 1)) : _createCommentVNode("", true), _ctx.desc ? (_openBlock(), _createElementBlock("h2", _hoisted_2, _toDisplayString(_ctx.desc), 1)) : _createCommentVNode("", true), _renderSlot(_ctx.$slots, "default", {}, undefined, true)], 2);
}
import {INewBase} from './news';

export const enum NEWS_ENUM {
    market = 'market',
    trends = 'trends',
}
export interface IHomeBanner {
    img_url: string;
    jump_url: string;
    img_alt?: string;
}

export interface IHomePartner {
    img_url: string;
    alt: string;
}

export interface IHomeNewsItem extends INewBase {
    title: string;
    jump_url: string;
}

type THomeRouterKeys = 'banner' | 'partner' | 'news';

export interface IHomeReq {
    routers: THomeRouterKeys[];
}
export interface IHomeNews {
    [NEWS_ENUM.trends]: IHomeNewsItem[];
    [NEWS_ENUM.market]: IHomeNewsItem[];
}

export interface IHomeRes {
    'banner': IHomeBanner[];
    'partner': {list: IHomePartner[]};
    'news': IHomeNews;
}
